import './main.css';

// START: Add Google Analytics
// Must be added before the chat widget script
import { getUrlParam } from './helpers/getUrlParam.js';

window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }
gtag('js', new Date());

gtag('config', 'G-1PHVHG6XHX', {
  'bot_id': getUrlParam('botId')
});

window.gtag = gtag; // Make gtag globally accessible
// END: Add Google Analytics

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

import './images/codepath_logo_1000w.png';
import './images/codepath-1x1_icon-white.png';
