import { getCurrentTimeAMPM } from './../helpers/timestamp';
import { marked } from "marked";

class ChatBubble {
  constructor() {
    // Import the HTML file using require
    const chatBubbleHTML = require('./../chatBubble-response.html').default;

    // Create a temporary div to hold the parsed HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = chatBubbleHTML;

    this.element = tempDiv.firstElementChild;

    this.element.querySelector('#chat-bubble-loading-text').innerHTML = this.getProcessingMessage();
  }

  writeInitialMessage(initialMessage, conversationStarters) {
    this.element.querySelector('#chat-bubble-loading').classList.add('hidden');
    this.element.querySelector('#chat-bubble').classList.remove('hidden');

    const chatBubbleElement = this.element;

    let i = 0;

    const type = () => {
      if (i < initialMessage.length) {
        chatBubbleElement.querySelector('#chat-bubble-text').innerHTML = this.formattedResponse(initialMessage.slice(0,i+1));
        i++;
        setTimeout(type, 5); // Adjust the delay between letters (in milliseconds)
      } else {
        this.showConversationStarters(conversationStarters);
      }
    };

    type();

    this.element.querySelector('#chat-bubble-timestamp').innerHTML = getCurrentTimeAMPM();
    this.element.querySelector('#chat-bubble-meta').classList.remove('hidden');
  }

  setBotResponse(response) {
    this.element.querySelector('#chat-bubble-loading').classList.add('hidden');
    this.element.querySelector('#chat-bubble-text').innerHTML = this.formattedResponse(response);
    this.element.querySelector('#chat-bubble').classList.remove('hidden');
    this.element.querySelector('#chat-bubble-timestamp').innerHTML = getCurrentTimeAMPM();
    this.element.querySelector('#chat-bubble-meta').classList.remove('hidden');

    this.element.querySelector('#chat-bubble-text').addEventListener('copy', event => this.handleCopy(event));
  }

  formattedResponse(response) {
    const renderer = {
      // code(string code, string infostring, boolean escaped)
      code(code, infostring, escaped) {
        const lang = (infostring || '').match(/^\S*/)?.[0];

        code = code.replace(/\n$/, '') + '\n';

        return '<div data-controller="clipboard"><pre><div class="text-xs flex justify-end"><button data-action="clipboard#copy" data-clipboard-target="button" class="rounded-full flex items-center gap-1 bg-purple text-white p-2"></button></div><code data-clipboard-target="source">'
          + code
          + '</code></pre></div>\n';
      },
      // link(href, title, text)
      link(href, title, text) {
        const link = marked.Renderer.prototype.link.call(this, href, title, text);
        return link.replace('<a', '<a target="_blank"');
      }
    };

    marked.use({ renderer });

    return marked.parse(response);
  }

  showConversationStarters(conversationStarters) {
    conversationStarters.forEach(starter => {
      const button = document.createElement('button');
      button.classList.add('chat-bubble-conversation-starter');
      button.dataset.action = 'click->chat#autoResize';
      button.innerHTML = starter.shortDisplayCopy;
      button.addEventListener('click', () => {
        const queryInput = document.querySelector('textarea[data-chat-target="query"]');
        queryInput.value = starter.query;
      });

      this.element.querySelector('#chat-bubble-conversation-starters').appendChild(button);
    });

    if (conversationStarters.length > 0) {
      this.element.querySelector('#chat-bubble-conversation-starters').classList.remove('hidden');
    }
  }

  scrollIntoView() {
    this.element.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }

  hide() {
    this.element.classList.add('hidden');
  }

  show() {
    this.element.classList.remove('hidden');
  }

  getProcessingMessage() {
    const messages = [
      "I'm consulting my digital crystal ball... 🔮 Stay tuned!",
      "Rustling through the digital archives for your answer... 📚✨ Hang tight!",
      "Brewing up some answers... ☕ This might take a moment!",
      "Beaming your request into the data nebula... 🌌 Please stand by!",
      "Crunching numbers and munching on data... 🤖🍪 Almost there!",
      "Summoning insights from the depths of the algorithm... 🧙‍♂️✨ Patience, young padawan!",
      "Dusting off the old knowledge library... 📖🕸️ Your wisdom is coming right up!",
      "Performing digital alchemy to transmute your query into gold... ⚗️✨ A little magic takes time!",
      "Sifting through the sands of information... 🏜️ Your oasis of knowledge is just ahead!",
      "Warping through the info-sphere at light speed... 🚀 Hold on to your hat!"
    ]

    return messages[Math.floor(Math.random() * messages.length)];
  }

  handleCopy(event) {
    // Get the selected text if any
    const selection = window.getSelection().toString();
    if (selection.length > 0) {
        if (typeof gtag !== "undefined") {
          gtag('event', 'bot_response_copied');
        }
    }
  }
}

export default ChatBubble;