// This file contains the functions that make requests to the API.

export const postMessage = async (options) => {
  const { message, botId, jwt, sessionNotFoundCallback, sessionNotFoundCallbackArgs, retried = false } = options;
  const url = `${PRODUCTION? 'https://' : 'http://'}${process.env.API_URL}/bot/message/`;
  const customHeaders = {
    'Authorization': 'Bearer ' + jwt,
  };

  const data = {
    message: message,
    bot_id: botId,
    ...(getSessionId() && { session_id: getSessionId() })
  };

  const response = await postData(url, customHeaders, data);

  let responseData;
  try {
    responseData = await response.json();
  }
  catch (error) {
    return {response, responseData: "Server Error"};
  }

  if (response.ok) {
    setSessionId(responseData.session_id);
  } else if (responseData.detail === 'ERR_BOT_SESSION_NOT_FOUND') {
    sessionNotFoundCallback.apply(null, sessionNotFoundCallbackArgs);
    clearSession();

    // Retry once
    if (retried == false) {
      options.retried = true
      return postMessage(options);
    }
  }

  return {response, responseData};
}


export const postData = async (url, additionalHeaders = {}, data) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        ...additionalHeaders,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response;
  } catch (error) {
    return error;
  }
}

const SESSION_ID_KEY = 'api_service_session_id';

export const clearSession = () => {
  sessionStorage.removeItem(SESSION_ID_KEY);
}

const setSessionId = (sessionId) => {
  sessionStorage.setItem(SESSION_ID_KEY, sessionId);
}

const getSessionId = () => {
  return sessionStorage.getItem(SESSION_ID_KEY);
}
