import { Controller } from "@hotwired/stimulus"
import { clearSession, postMessage } from '../apiService';
import { setupWebSocket } from '../websocketService';
import { getCurrentTimeAMPM } from './../helpers/timestamp';
import { getUrlParam } from "../helpers/getUrlParam";
import BotChatBubble from './../components/BotChatBubble';
import starters from './../../data/conversationStarters.json';

export default class extends Controller {
  static targets = ["query", "messages"]

  initialize() {
    clearSession();
    const initialMessage = getUrlParam('initialMessage') || "Hello! 👋 I'm a chatbot here to help you with any questions you might have. Feel free to ask me anything! 🤖";
    const title = getUrlParam('title') || "AI Chatbot";
    this.setTitle(title);
    this.showInitialMessage(initialMessage);
  }

  setTitle(title) {
    const titleElement = document.getElementById('title');
    titleElement.textContent = title;
  }

  async sendMessage(event) {
    event.preventDefault();

    // Check if the input is blank
    if (!this.queryTarget.value.trim()) {
      return; // Exit the function early if the input is blank
    }

    this.blockFormSubmission(event);
    const userChatBubbleElement = this.addUserQueryToUI();
    const botChatBubble = this.addBotChatBubble();
    await this.sendMessageToBot(userChatBubbleElement, botChatBubble);
  }

  enterSubmit(event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      // Prevent the default action to avoid newline
      event.preventDefault();
      // Submit form
      this.element.querySelector('form').requestSubmit();
    }
  }

  // Used to resize the textarea when a user is typing
  // Also keep the message content scrolled if user is at the bottom
  autoResize(event) {
    const textarea = this.queryTarget;
    const messagesContainer = this.messagesTarget;

    // The container is considered at the bottom if the scroll position (scroll top + container's visible height)
    // is equal to the total scrollable height
    const isMessagesAtBottom = messagesContainer.scrollHeight - messagesContainer.scrollTop === messagesContainer.clientHeight;

    // Resize input textarea
    textarea.style.height = 'auto';
    void textarea.offsetHeight; // Force reflow
    textarea.style.height = textarea.scrollHeight + 'px';

    if (isMessagesAtBottom) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }

  blockFormSubmission(event) {
    const input = this.queryTarget;
    const button = event.target.querySelector('button');

    // Disable the input and button
    input.disabled = true;
    button.disabled = true;

    button.classList.add('opacity-50');
    input.classList.add('opacity-50');
  }

  resetForm() {
    const input = this.queryTarget;
    const button = input.form.querySelector('button');

    // Re-enable the input and button
    input.disabled = false;
    button.disabled = false;

    button.classList.remove('opacity-50');
    input.classList.remove('opacity-50');

    input.value = '';
    input.style.height = 'auto'
    input.focus();
  }

  async sendMessageToBot(userChatBubbleElement, botChatBubble) {
    const query = this.queryTarget.value;
    const botId = getUrlParam('botId')
    const jwt = getUrlParam('jwt')

    const options = {
      message: query,
      botId: botId,
      jwt: jwt,
      sessionNotFoundCallback: this.displaySessionRestarted,
      sessionNotFoundCallbackArgs: [userChatBubbleElement, botChatBubble]
    };

    const { response, responseData } = await postMessage(options);

    if (response.ok) {
      this.displayBotResponse(botChatBubble, responseData.websocket_url);
    } else {
      this.displayErrorMessage(botChatBubble);
      console.error('Error sending message to bot:', responseData);
      return;
    }
  }

  addUserQueryToUI() {
    const query = this.queryTarget.value;

    // Import the HTML file using require
    const chatBubbleHTML = require('./../chatBubble.html').default;

    // Create a temporary div to hold the parsed HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = chatBubbleHTML;

    // Extract the first child of the temporary div (the created HTML)
    const chatBubbleElement = tempDiv.firstElementChild;

    chatBubbleElement.querySelector('#chat-bubble-text').textContent = query;
    chatBubbleElement.querySelector('#chat-bubble-timestamp').innerHTML = getCurrentTimeAMPM();

    // Append the chat bubble element to the document body or another parent element
    this.messagesTarget.appendChild(chatBubbleElement);

    return chatBubbleElement;
  }

  addBotChatBubble() {
    const chatBubble = new BotChatBubble();

    this.messagesTarget.appendChild(chatBubble.element);

    chatBubble.scrollIntoView();

    return chatBubble;
  }

  displayBotResponse(chatBubble, websocket_url) {
    setupWebSocket(process.env.API_URL + '/' + websocket_url, (event) => {
      chatBubble.setBotResponse(JSON.parse(event.data).message);
      chatBubble.scrollIntoView();

      this.resetForm();
    });
  }

  displayErrorMessage(chatBubble) {
    chatBubble.setBotResponse('Sorry, something went wrong. Please try again later.');

    chatBubble.scrollIntoView();

    this.resetForm();
  }

  showInitialMessage(response) {
    const chatBubble = new BotChatBubble();

    this.messagesTarget.appendChild(chatBubble.element);

    chatBubble.scrollIntoView();

    const conversationStarters = this.getStartersByBotId(getUrlParam('botId'));

    chatBubble.writeInitialMessage(response, conversationStarters);

    chatBubble.scrollIntoView();
  }

  displaySessionRestarted = async (chatBubbleElement, botChatBubble) => {
    const textOptions = [
      "Channeling my inner goldfish, I've just refreshed my memory pool! 🐠 Like them, I excel in short, sparkling conversations but tend to forget after a little while. Ready to splash into a new chat? Let's dive right back in!",
      "Like a goldfish exploring its bowl anew, I've refreshed our chat space for another round of delightful discoveries. 🌊 Shall we dive into another conversation with fresh curiosity?",
      "Embracing my goldfish moments! 🐟 I've just done a quick memory swirl in my bowl. Our conversations might be short, but they're always golden. Ready to bubble up some new ideas?"
    ]

    // Import the HTML file using require
    const newSessionHTML = require('./../newSession.html').default;

    // Create a temporary div to hold the parsed HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = newSessionHTML;

    // Extract the first child of the temporary div (the created HTML)
    const newSessionElement = tempDiv.firstElementChild;

    // Hide bot response bubble
    botChatBubble.hide();

    // Insert the new session element into the chat
    chatBubbleElement.insertAdjacentElement('afterend', newSessionElement);

    // Choose random new session copy and type to the screen
    const textContent = textOptions[Math.floor(Math.random() * textOptions.length)];

    for (let i = 0; i < textContent.length; i++) {
      await new Promise(resolve => setTimeout(() => {
        newSessionElement.querySelector('#new-session-text').innerHTML += textContent.charAt(i);
        resolve();
      }, 8));
    }

    // Wait for effect and add a new query bubble for user
    await new Promise(resolve => setTimeout(resolve, 500));

    const newUserQuery = this.addUserQueryToUI();
    this.messagesTarget.insertBefore(newUserQuery, botChatBubble.element);

    // Wait for effect before showing the bot's responding bubble again
    await new Promise(resolve => setTimeout(resolve, 500));

    botChatBubble.show();
    botChatBubble.scrollIntoView();
  }

  getStartersByBotId(botId) {
    const bot = starters.bots.find(b => b.botId === botId);
    return bot ? bot.starters : [];
  }
}