export function setupWebSocket(url, onMessageCallback) {
  var socket;
  var maxRetries = 5;
  var retries = 0;
  var retryDelay = 2000;

  function connectWebSocket() {
      socket = new WebSocket(`${PRODUCTION? 'wss://' : 'ws://'}` + url);

      socket.onopen = function() {
          console.log("WebSocket connection established");
          retries = 0;
      };

      socket.onmessage = function(event) {
          console.log('Message from server in websocketService.js');
          onMessageCallback(event);
          socket.close(1000, 'Closing connection normally');
      };

      socket.onerror = function(event) {
          console.error("WebSocket error observed:", event);
      };

      socket.onclose = function(event) {
          console.log("WebSocket connection closed");
          if (!event.wasClean && retries < maxRetries) {
              setTimeout(connectWebSocket, retryDelay);
              retries++;
          }
      };
  }

  connectWebSocket();
}